import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Information from "../components/Information";
import Map from "../components/Map";

import "../App.css";

const Privacy = () => (
  <Layout>
    <PageHeader text="Privacy Policy" />
    <PrivacyPolicy />
    <Information />
    <Map />
  </Layout>
);

export default Privacy;
